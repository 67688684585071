//
//
//
//

import flatMap from 'lodash/flatMap'
import uniq from 'lodash/uniq'
import includes from 'lodash/includes'

const iconsDeep = (() => {
  const keys = require.context('./assets/icons', true, /\.svg$/).keys()
  return keys.map(key => key.replace(/(^.\/)|(\.svg$)/g, ''))
})()
const icons = uniq(flatMap(iconsDeep, iconDeep => iconDeep.split('/')[0]))

export default {
  name: 'CurrencyIcon',
  props: {
    name: {
      type: String,
      require: true,
    },
  },
  computed: {
    iconComponent () {
      return require('./assets/icons/' +
          this.pathDynamicPart +
          '.svg?inline')
    },
    pathDynamicPart () {
      return [includes(icons, this.name) ? this.name : 'IDN'].join('/')
    },
  },
}
